import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { EnvService } from './env.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private env: any;

  constructor(private storageService: StorageService, private envService: EnvService, private http: HttpClient) {
    this.env = envService.getEnv();
  }

  private logIn(token: string): void {
    this.storageService.getStorage().setItem(ACCESS_TOKEN, token);
  }

  public isLoggedIn(): boolean {
    // return true;
    const token = this.getToken();
    if (!token || !token.includes('.')) {
      return false;
    }
    const jwt = JSON.parse(atob(token.split('.')[1]));
    return jwt['exp'] && ((jwt['exp'] * 1000) > Date.now());
  }

  public getToken(): string {
    return this.storageService.getStorage().getItem(ACCESS_TOKEN);
  }

  public deleteToken(): void {
    this.storageService.getStorage().removeItem(ACCESS_TOKEN);
  }

  public getSSOUrl(): string {
    return  `${this.env.ssoURL}/oauth/`;
  }

  public getCodeUrl(): string {
    return  `${this.getSSOUrl()}authorize?response_type=code&client_id=${this.env.ssoClientId}&scope=all&state=1`;
  }

  public setTokenFromServer(code: string): Observable<string> {
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(`${this.env.ssoClientId}:${this.env.ssoSecret}`)
    });
    const params = new HttpParams({fromObject: {
      'code': code,
      'grant_type': 'authorization_code',
    }});
    return this.http.post(`${this.getSSOUrl()}token`, null, {headers, params, observe: 'body', responseType: 'json'}).pipe(
      pluck(ACCESS_TOKEN),
      tap(token => this.logIn(token as string)),
    ) as Observable<string>;
  }
}

export const ACCESS_TOKEN = 'access_token';

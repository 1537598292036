import { Component, ViewChild } from '@angular/core';
import { MessageService } from '../services/message.service';
import { ToastComponent, ToastBeforeOpenArgs } from '@syncfusion/ej2-angular-notifications';

@Component({
  selector: 'snap-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent {
  @ViewChild('toast', { static: true }) toast: ToastComponent;

  constructor(private messageService: MessageService) { }

  onCreate() {
    this.messageService.getMessages().subscribe(message => this.toast.show({ content: message }));
  }

  onBeforeOpen(e: ToastBeforeOpenArgs): void {
    e.cancel = this.preventDuplicate(e);
  }

  preventDuplicate(e: ToastBeforeOpenArgs): boolean {
    const toastEle: HTMLElement = e.element;
    const toasts: HTMLCollection = e.toastObj.element.children;
    for (let i = 0; i < toasts.length; i++) {
      if (toasts.item(i).querySelector('.e-toast-message').isEqualNode(toastEle.querySelector('.e-toast-message'))) {
        return true;
      }
    }
    return false;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/app/core';
import { LocService } from 'src/app/core/services/loc.service';
import { Observable, of } from 'rxjs';
import { pluck, mergeMap } from 'rxjs/operators';

const DEFAULT_PAGE = '/reports';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public token: Observable<string>;

  constructor(private locService: LocService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getCodeFromURL().pipe(
        mergeMap(code => (code) ? this.authService.setTokenFromServer(code) : of(code)
      )
    ).subscribe(_ => {
      this.redirect();
    });
  }

  public isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  public redirect(): void {
    if (this.isLoggedIn()) {
      this.router.navigateByUrl(DEFAULT_PAGE);
    } else {
      this.locService.getLoc().href = this.authService.getCodeUrl();
    }
  }

  getCodeFromURL(): Observable<string | null> {
    return this.route.queryParams.pipe(
      pluck('code')
    );
  }
}

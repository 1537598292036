import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { EnvService } from './env.service';
import { Info } from '../models';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  private info$: Subject<Info>;

  constructor(private envService: EnvService,
    private httpClient: HttpClient) { }

  public getInfo(): Observable<Info> {
    if (!this.info$) {
      this.info$ = new ReplaySubject(1);
      this.httpClient.get<Info>(`${this.envService.getEnv().apiURL}/info`).subscribe(this.info$);
    }
    return this.info$.asObservable();
  }
}

import { Area } from './area';

export class Kpi {
    constructor(public month: string, public rows: KpiRow[]) {}
}

export interface KpiRow {
  id?: number;
  rowTitle: string;
  monthlyActual: number | string;
  monthlyGoal: number | string;
  monthlyVar?: number;
  ytdActual?: number;
  ytdGoal?: number;
  pytdActual?: number;
  variance?: number;
  format: string;
  area: Area;
  order: number;
  precision: string;
  red: string;
  hideOnReport?: boolean;
}

export interface KpiDetail {
  row: {
    title: string,
    format: string,
    area: Area,
    order: number,
    precision: string,
    red: string,
  };
  currentMonth?: MonthDetail;
  pytdActual?: number;
}

export interface MonthDetail {
  datumId: number;
  date: string;
  actual: number;
  goal: number;
  monthlyVar?: number;
  ytdActual?: number;
  ytdGoal?: number;
  pytdActual?: number;
  variance?: number;
}

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocService {

  loc: Location;

  constructor(@Inject(DOCUMENT) document: Document) {
    this.loc = document.location;
  }

  getLoc(): Location {
    return this.loc;
  }
}

import { Component } from '@angular/core';

@Component({
  template: `<p>User not found. Please contact the system administrator if you think you reached this page by error.</p>`
})
export class UserNotFoundComponent {

  constructor() { }

}

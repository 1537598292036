export enum Dot {
  empty = '',
  red = 'R',
  yellow = 'Y',
  green = 'G'
}

export const dot = (color: string): Dot => {
  switch (color) {
    case 'R':
    case 'red':
    case 'RED':
      return Dot.red;
    case 'Y':
    case 'yellow':
    case 'YELLOW':
      return Dot.yellow;
    case 'G':
    case 'green':
    case 'GREEN':
      return Dot.green;
    default:
      return Dot.empty;
  }
};

export const randomDot = () => {
  switch (Math.floor(Math.random() * 3) + 1) {
    case 1: return Dot.red;
    case 2: return Dot.yellow;
    case 3: return Dot.green;
  }
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToastModule } from '@syncfusion/ej2-angular-notifications';

import { MessagesComponent } from './messages/messages.component';

@NgModule({
  imports: [
    CommonModule,
    ToastModule,
  ],
  declarations: [
    MessagesComponent
  ],
  exports: [
    MessagesComponent,
    ToastModule
  ]
})
export class CoreModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Organization, createOrganizationReport, OrganizationReport, ReportData, Report } from '../models';
import { EnvService } from './env.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private reportsUrl: string;

  constructor(private envService: EnvService, private httpClient: HttpClient) {
    this.reportsUrl = `${this.envService.getEnv().apiURL}/reports`;
  }

  public getReports(organization: Organization): Observable<OrganizationReport> {
    return this.httpClient.get<ReportData[]>(`${this.reportsUrl}/${organization.organizationId}/recent`).pipe(
      map(reports => createOrganizationReport(organization, reports))
    );
  }

  public getHistoricalReports(organization: Organization, ending: string): Observable<OrganizationReport> {
    return this.httpClient.get<ReportData[]>(`${this.reportsUrl}/${organization.organizationId}/weekly?ending=${ending}`).pipe(
      map(reports => createOrganizationReport(organization, reports))
    );
  }

  public getMonthly(organization: Organization): Observable<OrganizationReport> {
    return this.httpClient.get<ReportData[]>(`${this.reportsUrl}/${organization.organizationId}/monthly`).pipe(
      map(reports => createOrganizationReport(organization, reports))
    );
  }

  public getHistoricalMonthly(organization: Organization, month: string): Observable<OrganizationReport> {
    return this.httpClient.get<ReportData[]>(`${this.reportsUrl}/${organization.organizationId}/monthly?month=${month}`).pipe(
      map(reports => createOrganizationReport(organization, reports))
    );
  }

  public saveReports(reports: Report[]): Observable<boolean> {
    const postOptions = { observe: 'response' as 'response', responseType: 'json' as 'json' };
    // const reports = organizationReport.areaReports.map(cr => cr.findCurrentWeek());
    return this.httpClient.post(this.reportsUrl, reports, postOptions).pipe(
      map(res => res.status >= 200 && res.status < 400)
    );
  }
}

import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private message$: Subject<string> = new Subject();

  constructor() { }

  public add(message: string): void {
    this.message$.next(message);
  }

  public getMessages(): Observable<string> {
    return this.message$.asObservable();
  }
}

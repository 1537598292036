import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  public now: () => Date;

  constructor(@Inject(LOCALE_ID) private locale: string) {
    this.now = () => new Date();
  }

  public formattedDate(): string {
    return formatDate(this.now(), 'yyyy-MM-dd', this.locale);
  }

  public getFullNameOfMonth(month: number | null = null): string {
    const date = !!month ? new Date(this.now().getUTCFullYear(), month - 1, 1) : this.now();
    return formatDate(date, 'MMMM', this.locale);
  }

  public getShortNameOfMonth(month: number | null = null): string {
    const date = !!month ? new Date(this.now().getUTCFullYear(), month - 1, 1) : this.now();
    return formatDate(date, 'MMM', this.locale);
  }

  public yearMonthFormat(yearMonth: string): string {
    const month = Number.parseInt(yearMonth.slice(-2), 10);
    return this.getFullNameOfMonth(month);
  }

  public mediumDateFormat(utcDate: string): string {
    return formatDate(this.toDate(utcDate), 'mediumDate', this.locale);
  }

  private toDate(utcDate: string): Date {
    const tempDate = new Date(utcDate);
    tempDate.setMinutes(tempDate.getMinutes() + tempDate.getTimezoneOffset());
    return tempDate;
  }
}

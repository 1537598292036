import { Organization } from './organization';

export interface UserData {
  displayName: string;
  title: string;
  image: string;
  organizations: Organization[];
  roles: string;
}

export class User implements UserData {

  constructor(
    public readonly displayName: string,
    public readonly title: string,
    public readonly image: string,
    public readonly organizations: Organization[],
    public readonly roles: string) { }

    public static from(data: UserData): User {
      return new User(data.displayName, data.title, data.image, data.organizations, data.roles);
    }
}

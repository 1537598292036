import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { formatNumber, formatCurrency, formatPercent } from '@angular/common';

import { ICellFormatter, Column } from '@syncfusion/ej2-grids';

import { KpiDetail, KpiRow } from '../models';

@Injectable({
  providedIn: 'root'
})
export class KpiGridHelperService {
  static RED = 'red-text';
  static GREEN = 'green-text';

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  public static getColor = (num: number, red: string): string => {
    const pm = num >= 0;
    return ((pm && red === '+') || (!pm && red === '-')) ? KpiGridHelperService.RED : KpiGridHelperService.GREEN;
  }

  public formatData = (num: number, format: string, precision: number): string => {
    const digitInfo = `1.${ precision }-${ precision }`;
    switch (format) {
      case 'c':
      case 'cf':
        return formatCurrency(num, this.locale, '$', 'USD', digitInfo);
      case 'p':
        return formatPercent(num / 100.0, this.locale, digitInfo);
      case 'v':
        return formatPercent(Math.abs(num / 100.0), this.locale, digitInfo);
      case 'x':
        const formatted = formatNumber(num, this.locale, digitInfo);
        return (formatted === '') ? '' : `${formatted}x`;
      case 'd':
      default:
          return formatNumber(num, this.locale, digitInfo);
    }
  }

  public formatKPIData = (r: KpiRow, context: string): Object[] => {
    const num = r[context] as number;
    const format = r.format;
    const precision = parseInt(r.precision, 10);
    if (context === 'monthlyVar' || context === 'variance') {
      return [this.formatData(num, 'v', 1)];
    }
    return [this.formatData(num, format, precision)];
  }

  public getCustomFormatter = (): ICellFormatter => {
    return {
      getValue: (column: Column, data: Object): Object => {
        if (data[column.field] === null || data[column.field] === undefined || Number.isNaN(data[column.field])) {
          return '';
        }
        return this.formatData(data[column.field], data['format'], data['precision']);
      }
    };
  }

  public getPercentageFormatter = (): ICellFormatter => {
    return {
      getValue: (column: Column, data: Object): Object => {
        if (data[column.field] === null || data[column.field] === undefined || Number.isNaN(data[column.field])) {
          return '';
        }
        return this.formatData(data[column.field], 'p', data['precision']);
      }
    };
  }

  public getVarianceFormatter = (): ICellFormatter => {
    return {
      getValue: (column: Column, data: Object): Object => {
        if (data[column.field] === null || data[column.field] === undefined || Number.isNaN(data[column.field])) {
          return '';
        }
        return this.formatData(data[column.field], 'v', 1);
      }
    };
  }
}

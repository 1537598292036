import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, UserData } from '../models';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userInfo$: ReplaySubject<User>;

  constructor(private envService: EnvService,
    private httpClient: HttpClient) {
    }

  public getUserInfo(force: boolean = false): Observable<User> {
    if (force || !this.userInfo$) {
      if (!this.userInfo$) {
        this.userInfo$ = new ReplaySubject(1);
      }
      this.retrieveUser().subscribe(this.userInfo$);
    }
    return this.userInfo$;
  }

  retrieveUser(): Observable<User> {
    return this.httpClient.get<UserData>(`${this.envService.getEnv().apiURL}/users/current`).pipe(
      map(u => User.from(u))
    );
  }
}

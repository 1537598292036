import { Dot, dot as dotFrom } from './dot';
import { Area } from './area';
import { Organization } from './organization';

export interface ReportData {
  reportId: number;
  organization: Organization;
  area: Area;
  date: string | null;
  comment: string;
  dot: string | null;
  score: string | null;
  submitted: string | null;
  reportMonth: string | null;
}

export class Report implements ReportData {
  score: string;

  constructor(
    public organization: Organization,
    public area: Area,
    public date: string,
    public comment: string,
    public dot: Dot,
    public reportId = null,
    public submitted = null,
    public reportMonth = null) { }

  public static from(data: ReportData): Report {
    return new Report(
      data.organization,
      data.area,
      data.date,
      data.comment,
      dotFrom(data.dot || data.score),
      data.reportId,
      data.submitted,
      data.reportMonth
    );
  }

  public isRed(): boolean { return this.dot === Dot.red; }
  public isYellow(): boolean { return this.dot === Dot.yellow; }
  public isGreen(): boolean { return this.dot === Dot.green; }
  public isEmpty(): boolean { return this.dot === Dot.empty; }
}

export const nullReport = new Report(
  { organizationId: null, name: null, level: null, areas: null }, { areaId: null, displayName: null }, null, null, Dot.empty);


const calculateSortValue = (report: Report): number => {
  return report.date && report.date.length > 7 ? new Date(report.date).valueOf() : Number.MAX_SAFE_INTEGER;
};

const sortReports = (reports: Report[]): Report[] => {
  return reports.sort((a, b) => calculateSortValue(a) - calculateSortValue(b));
};

export class AreaReport {
  public subOrganizationReports: AreaReport[] = [];

  constructor(
    public organization: Organization,
    public area: Area,
    public reports: Report[]) { }

  public static from(data: AreaReportData): AreaReport {
    return new AreaReport(data.organization, data.area, data.reports.map(Report.from));
  }

  public findCurrentWeek(): Report {
    return sortReports(this.reports).slice(-1)[0];
  }

  public findLastWeek(): Report {
    const lastWeeks = sortReports(this.reports);
    return lastWeeks.slice(-2)[0];
  }
}

export interface AreaReportData {
  organization: Organization;
  area: Area;
  reports: ReportData[];
}

export class OrganizationReport {
  constructor(
    public organization: Organization,
    public areaReports: AreaReport[]) { }

  public findArea(area: Area): AreaReport {
    return this.areaReports.find(cr => cr.area.areaId === area.areaId);
  }

  public setSubOrganizationReports(subOrganizationReports: OrganizationReport[]) {
    subOrganizationReports.forEach(ur => ur.areaReports.map(cr => {
      const a = this.findArea(cr.area)
      if (a) {
        a.subOrganizationReports.push(cr);
      }
    }));
  }

  public normalCategories(): AreaReport[] {
    return this.areaReports.filter(cr => !cr.area.additional && this.organization.areas.find(oa => oa.areaId === cr.area.areaId));
  }

  public additionalArea(): AreaReport {
    return this.areaReports.filter(cr => cr.area.additional)[0];
  }
}

const createAreaReport = (organization: Organization, area: Area, reportsIn: ReportData[]): AreaReport => {
  const reports = reportsIn.filter(r => r.area.areaId === area.areaId);
  return new AreaReport(
    organization,
    area,
    sortReports(reports.map(Report.from))
  );
};

export const createOrganizationReport = (organization: Organization, reports: ReportData[]): OrganizationReport => {
  const organizationReports = reports.filter(r => r.organization.organizationId === organization.organizationId);
  if (organizationReports.length == 0 && organization.level != 'COMPANY') {
    return null;
  }
  const baseReport = new OrganizationReport(
    organization,
    organization.areas.sort((a, b) => a.displayOrder - b.displayOrder).map(c => createAreaReport(organization, c, organizationReports))
  );
  if (organization.directReports && organization.directReports.length) {
    baseReport.setSubOrganizationReports(organization.directReports.map(su => createOrganizationReport(su, reports)).filter(sor => !!sor));
  }
  return baseReport;
};

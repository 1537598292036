import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login/login.component';
import { UserNotFoundComponent } from './login/user-not-found/user-not-found.component';
import { LoginModule } from './login/login.module';

const routes: Routes = [
  { path: '', component: LoginComponent, data: { page: -1 } },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'userNotFound', component: UserNotFoundComponent, data: { page: -2 } }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    LoginModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, mapTo, mergeMap } from 'rxjs/operators';

import { EnvService } from './env.service';
import { Organization, Area, Kpi, KpiRow, KpiDetail } from '../models';
import { DateService } from './date.service';
import { InfoService } from './info.service';

@Injectable({
  providedIn: 'root'
})
export class KpiService {

  static calcYearMonth(date: Date): string {
    return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}`;
  }

  constructor(private envService: EnvService,
    private dateService: DateService,
    private infoService: InfoService,
    private httpClient: HttpClient) { }

  public getKpis(organization: Organization, date: string, area?: Area): Observable<Kpi> {
    let params: HttpParams = new HttpParams()
      .set('yearmonth', date)
      .set('orgId', organization.organizationId.toString());
    if (area) {
      params = params.set('areaId', area.areaId.toString());
    }
    return this.httpClient.get<KpiRow[]>(`${this.envService.getEnv().apiURL}/kpi`, { params })
      .pipe(
        map(rows => rows.sort((a, b) => a.order - b.order)),
        map(rows => new Kpi(this.dateService.getShortNameOfMonth(Number.parseInt(date.slice(-2), 10)), rows))
    );
  }

  public getKpiDetails(organization: Organization, dateIn?: Date): Observable<KpiDetail[]> {
    const date$ = (dateIn == null)
      ? this.infoService.getInfo().pipe(
        map(info => {
          const yearmonth = this.dateService.now();
          const offSetMonths = 1;
          yearmonth.setMonth(yearmonth.getMonth() - offSetMonths);
          return yearmonth.toISOString().slice(0, 7);
        }))
      : of(KpiService.calcYearMonth(dateIn));
    return date$.pipe(mergeMap(
      date => {
        const params: HttpParams = new HttpParams()
          .set('yearmonth', date)
          .set('orgId', organization.organizationId.toString());
        return this.httpClient.get<KpiDetail[]>(`${this.envService.getEnv().apiURL}/kpi/detail`, { params });
      }
    ));
  }

  public saveKpis(kpi: Kpi): Observable<boolean> {
    return this.httpClient.patch(`${this.envService.getEnv().apiURL}/kpi`, kpi.rows)
      .pipe(
        mapTo(true)
      );
  }
}
